<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogAddTransation"
    :width="screenWidth + '%'"
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-transation">
          <p class="title-add-transation">
            Añadir transacción a operación: {{ sReference }}
          </p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-text-fields">
          <v-container>
            <v-row>
              <!-- #region Cuenta -->
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-select
                  :loading="bLoadAccounts"
                  :disabled="bLoadAccounts"
                  v-model="sSelectedAccountId"
                  :items="itemsAccounts"
                  item-text="sName"
                  :persistent-placeholder="true"
                  placeholder="Cuenta"
                  item-value="sAccountId"
                  class="global-select"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  outlined
                  @change="validateForm()"
                >
                  <template slot="label">
                    <span>
                      Cuenta
                      <span class="important-data"> * </span>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- #endregion Cuenta -->

              <!-- #region Tipo de cambio -->
              <v-col v-if="bCurrencyMix" cols="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field-currency
                  v-model="dExchangeRate"
                  outlined
                  label="Tipo de cambio"
                  placeholder="0.00"
                  :bImportantData="true"
                  :options="oOptionCurrencyExchangeRateGlobal"
                />
              </v-col>
              <!-- #endregion Tipo de cambio -->

              <!-- #region Cantidad asociada -->
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field-currency
                  :disabled="sSelectedAccountId !== null ? false : true"
                  v-model="sCurrency"
                  outlined
                  label="Cantidad asociada"
                  placeholder="0.00"
                  :bImportantData="true"
                  :options="oCurrencyOption"
                />
              </v-col>
              <!-- #endregion Cantidad asociada -->

              <!-- #region Operación -->
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-select
                  v-model="sOperation"
                  :items="itemsOperation"
                  item-text="text"
                  item-value="value"
                  label="Operación"
                  class="global-select"
                  color="var(--primary-color-border-menu-profile)"
                  background-color="var(--primary-color-menu)"
                  outlined
                ></v-select>
              </v-col>
              <!-- #endregion Operación -->

              <!-- #region Descripción -->
              <v-col cols="12">
                <v-textarea
                  v-model="sDescription"
                  label="Descripción"
                  auto-grow
                  placeholder="Descripción..."
                  class="global-text-field"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  persistent-placeholder
                  maxlength="255"
                  outlined
                  rows="4"
                  row-height="50"
                >
                </v-textarea>
              </v-col>
              <!-- #endregion Descripción -->
            </v-row>
          </v-container>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              class="global-btn-primary"
              :disabled="!bAdd"
              :loading="bLoading"
              @click="addTransation()"
            >
              Registrar transacción
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogAddTransation: Boolean,
    sCurrencyCode: String,
    iCountRowsTransaction: Number,
    sOrderId: String,
    sReference: String,
    sType: String,
  },
  data() {
    return {
      screenWidth: 0,
      bAdd: false,
      bLoading: false,
      sCurrency: null,
      sTransationType: null,
      itemTransationType: [
        {
          text: "Seleccionar tipo de transacción...",
          value: null,
          disabled: true,
        },
        { text: "Depósito", value: "DEPOSIT" },
        { text: "Retiro", value: "WITHDRAW" },
      ],
      sSelectedAccountId: null,
      itemsAccounts: [],
      dExchangeRate: null,
      bLoadAccounts: false,
      sDescription: "",
      menu: false,
      dates: "",
      sMinDate: new Date().toISOString().slice(0, 10),
      sOperation: "+",
      itemsOperation: [
        { text: "Cobro", value: "+" },
        { text: "Pago", value: "-" },
      ],
      oCurrencyOption: {},
      bCurrencyMix: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    getAccounts() {
      this.bLoadAccounts = true;
      this.itemsAccounts = [];
      DB.get(`${URI}/api/sp/v1/accounts`, {
        params: {
          bSort: true,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoadAccounts = false;
          // if (this.iCountRowsTransaction === 0) {
          this.itemsAccounts = response.data.results;
          // } else {
          // "cf37a729-0384-4ae0-8be8-60575e60c603" MXN
          // "ca3bf61e-6fb1-4ff3-adfd-1cb5c6b99c48" USD
          //   this.itemsAccounts = response.data.results.filter(
          //     (eAccounts) =>
          //       eAccounts.oCurrency.sCurrencyId ===
          //       this.sCurrencyCode
          //   );
          // }
          /* this.itemsAccounts.push({
            sName: "Seleccionar cuenta...",
            sSelectedAccountId: null,
            disabled: true,
          }) */
        })
        .catch((error) => {
          this.bLoadAccounts = false;
          this.Error(error.response.data);
        });
    },
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 80;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 60;
      }
    },
    close() {
      this.$emit("close");
      this.bAdd = false;
      this.bLoading = false;
      this.sAccountType = null;
      this.sSelectedAccountId = null;
      this.sTransationType = null;
      this.sCurrency = null;
      this.sOperation = "+";
      this.sDescription = "";
      this.bCurrencyMix = false;
      this.dExchangeRate = null;
      this.dates = "";
      this.itemsAccounts = [];
      this.oCurrencyOption = {};
    },
    addTransation() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAccount) {
            const payload = {
                dAmount: parseFloat(this.sOperation + this.sCurrency),
                dExchangeRate: this.dExchangeRate ? this.dExchangeRate : 0,
                sDescription: this.sDescription,
                // tTransactionDate: this.dates,
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.post(
              `${URI}/api/sp/v1/orders/general/${this.sOrderId}/${this.sSelectedAccountId}/transaction`,
              payload,
              config
            )
              .then((response) => {
                this.Success(response.data.message);
                this.close();
                this.$store.commit("refresher", true);
                this.$emit("update");
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    validateForm() {
      this.bAdd =
        this.sCurrency > 0 &&
        this.sSelectedAccountId !== "" &&
        this.sSelectedAccountId !== null; // &&
      // this.sDescription !== "" &&
      // this.sTransationType !== null &&
      // this.dates !== "" && this.dates !== null ;
    },
    save(date) {
      this.$refs.menu.save(date);
      // let fecha_inicial = this.dates[0];
      // let fecha_final = this.dates[1];

      // if (this.dates.length > 1) {
      //   if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
      //     let lastDate = this.dates[1];
      //     this.dates = [];
      //     this.dates[0] = lastDate;
      //   } else {
      //     this.$emit("setDate", {
      //       tStart: fecha_inicial,
      //       tEnd: fecha_final,
      //     });
      //   }
      // }
    },
    getCurrencyOptions(sAccountId) {
      let sCurrencyTemp = this.itemsAccounts.find(
        (eAccount) => eAccount.sAccountId === sAccountId
      ).oCurrency.sCurrencyId;
      switch (sCurrencyTemp) {
        case "cf37a729-0384-4ae0-8be8-60575e60c603": //MXN
          this.oCurrencyOption = this.oOptionCurrencyMXNGlobal;
          break;
        case "ca3bf61e-6fb1-4ff3-adfd-1cb5c6b99c48": //USD
          this.oCurrencyOption = this.oOptionCurrencyUSDGlobal;
          break;

        default:
          this.oCurrencyOption = this.oOptionCurrencyMXNGlobal;

          break;
      }
    },
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    bDialogAddTransation(e) {
      if (e) {
        this.getAccounts();
      }
    },
    sSelectedAccountId() {
      if (this.sSelectedAccountId) {
        let dAmountTemp = this.itemsAccounts.find(
          (eAccount) => eAccount.sAccountId === this.sSelectedAccountId
        ).oCurrency.sCurrencyId;

        if (this.sCurrencyCode) {
          if (dAmountTemp !== this.sCurrencyCode) {
            this.bCurrencyMix = true;
          } else {
            this.bCurrencyMix = false;
            this.dExchangeRate = null;
          }
        } else {
          this.bCurrencyMix = false;
          this.dExchangeRate = null;
        }
        this.getCurrencyOptions(this.sSelectedAccountId);
      }
    },
    sCurrency() {
      this.validateForm();
    },
    dates() {
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.title-add-transation {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.btn-icon-color-dynamic {
  border: solid var(--primary-color-text) 1px;
  border-radius: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 26px;
  width: 26px;
}

.icon-color-dynamic {
  width: 100%;
  height: 100%;
}

.btn-arrow-down-color {
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content-title-transation {
  width: 100%;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 250px;
}

.content-btn-second {
  width: 250px;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
